@use "src/assets/styles/colors.scss";
@use "src/assets/styles/mixins.scss";
$element_height_sm: 160px;
$element_width_sm: 200px;
$element_height_lg: 170px;
$element_width_lg: 260px;
.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 2rem;
}
.item {
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 3rem;
  max-width: 400px;
  box-sizing: border-box;
  width: 100%;
  .info {
    position: relative;
    &::before {
      @include mixins.list-dot(rgb(151, 151, 151));
    }
  }
  .techs {
    opacity: 0.65;
    font-size: 0.85rem;
  }
  .title {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
  }
  .description {
    margin-bottom: 1.5rem;
  }
  .thumbnail {
    position: relative;
    width: 100%;
    padding-top: 56%;
    background-color: colors.$blue_primary;
    opacity: 0.5;
    border-radius: 5px;
    img {
      width: 90%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  // .overlay {
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   top: 0;
  //   overflow: hidden;
  //   box-sizing: border-box;
  // }
}

@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 768px) {
  .container {
    padding: 2rem 3rem;
  }
  .item {
    flex-direction: row;
    justify-content: space-between;
    max-width: 700px;
    .thumbnail {
      height: calc(280px * 0.56);
      width: 280px;
      padding-top: 0;
    }
  }
  .text {
    width: 50%;
  }
}
