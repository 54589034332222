.container {
  background-color: #2a3b48;
  padding: 2rem;
  color: white;
  text-align: center;
}

.name {
  font-size: 1.25rem;
  font-weight: 600;
  opacity: 0.5;
  margin-bottom: 0.5rem;
}

.text {
  opacity: 0.5;
  font-size: 0.9rem;
  max-width: 400px;
  margin: auto;
  margin-bottom: 1.5rem;
}

.socials {
  margin-bottom: 1.5rem;
  a {
    color: white;
    opacity: 0.5;
    margin: 0 0.75rem;
    display: inline-block;
    &:hover {
      opacity: 0.75;
      svg {
        transform: scale(1.25);
      }
    }
  }
  svg {
    font-size: 2rem;
    transition: all 0.2s ease;
  }
}

.credit {
  font-size: 0.75rem;
  opacity: 0.5;
}
