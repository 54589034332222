@use "src/assets/styles/mixins.scss";
.container {
  background-color: #222222;
  color: white;
  padding: 2rem;
}
.items {
  padding-left: 1.5rem;
}

.item {
  margin: auto;
  margin-bottom: 2rem;
  max-width: 400px;
  .overtitle {
    opacity: 0.75;
  }
  .title {
    font-weight: 700;
  }
  .info {
    position: relative;
  }
  .info::before {
    @include mixins.list-dot(rgba(255, 255, 255, 0.521));
  }
}
