.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1.5rem;
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .swiper-container {
    max-width: 1200px;
  }
}
