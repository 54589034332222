@use "src/assets/styles/colors";
@use "src/assets/styles/constants";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: constants.$navigation-height;
  box-sizing: border-box;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  transform: scale(1);
  transition: all 0.2s ease;
  &.sticky {
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.178);
  }
}
.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    max-height: 28px;
  }
}
.open,
.close {
  cursor: pointer;
  position: absolute;
  left: 1.5rem;
}
.list_desktop {
  display: none;
}
.contact {
  font-size: 0.75rem;
  background-color: colors.$blue_primary;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  color: white;
  &:hover {
    border-bottom: 2px solid transparent;
  }
}
.mobile_nav_overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.685);
  display: none;
  transition: 1s ease;
}
.mobile_nav_container {
  position: fixed;
  width: 0;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: white;
  overflow-x: hidden;
  transition: 0.3s;
  z-index: 100;
  .close {
    $h: 24px;
    $w: 4px;
    width: $h;
    height: $h;
    top: 1.25rem;
    &::before,
    &::after {
      position: absolute;
      background-color: #4e4e4e;
      content: "";
      height: $h;
      width: $w;
      left: 10px;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
  .list_mobile {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 1.5rem;
      a {
        color: black;
        font-size: 1.5rem;
        cursor: pointer;
        position: relative;
        &::after {
          transform: translateY(5px);
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 3px;
          opacity: 0;
          content: "";
          background-color: colors.$blue_primary;
          transition: all 0.2s ease-out;
        }
        &.active::after {
          opacity: 0.75;
          transform: translateY(0px);
        }
        &:hover:not(.active):not(.contact)::after {
          opacity: 0.25;
          transform: translateY(0px);
        }
      }
    }
  }
}
.mobile_nav_container_opened {
  width: 50%;
}
.mobile_nav_overlay_opened {
  display: block;
}
.mobile_nav_overlay_opened ~ .mobile_nav_container {
  width: 50%;
}
@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 768px) {
  .container {
    padding: 0 2rem;
    justify-content: space-between;
  }
  a {
    color: #4e4e4e;
    text-decoration: none;
  }
  .logo {
    position: static;
    transform: none;
    img {
      max-height: 32px;
    }
  }
  .contact_mobile {
    display: none;
  }
  .open {
    display: none;
  }
  .list_desktop {
    display: flex;
    margin: 0;
    li {
      a {
        margin: 0 1.75rem;
        padding: 0.5rem 0;
        border: 2px solid transparent;
        display: block;
        position: relative;
        cursor: pointer;
        &::after {
          transform: translateY(5px);
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 3px;
          opacity: 0;
          content: "";
          background-color: colors.$blue_primary;
          transition: all 0.2s ease-out;
        }
        &.active:not(.contact)::after {
          opacity: 0.75;
          transform: translateY(0px);
        }
        &:hover:not(.active):not(.contact)::after {
          opacity: 0.25;
          transform: translateY(0px);
        }
        &.contact {
          background-color: colors.$blue_primary;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          margin-right: 0;
          color: white;
          &:hover {
            border-bottom: 2px solid transparent;
          }
        }
      }
    }
  }
}
