@use "src/assets/styles/colors.scss";
.container {
  background-color: #f7fcff;

  h2 {
    margin-bottom: 1rem;
    color: #4e4e4e;
    font-size: 1.75rem;
  }
  .subtitle {
    margin-bottom: 6rem;
  }
  h3 {
    color: #4e4e4e;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
.feature_item {
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
  text-align: center;
  transition: all 0.2s ease-out;
  .description {
    font-weight: 400;
  }
  .skill_list {
    margin: 1rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .list_item {
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
    }
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      opacity: 0.5;
      background-color: colors.$blue_primary;
      margin-right: 0.75rem;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .feature_item {
    padding: 5rem 3rem;
  }
}
