@use "src/assets/styles/colors.scss";
.container {
  padding-top: 2rem;
  text-align: center;
  color: #484848;
  margin-bottom: 1rem;
}
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
  .img_container {
    margin-bottom: 3rem;
    img {
      height: 200px;
      width: 100%;
    }
  }
  small {
    color: #f85656;
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  input,
  textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-height: 2rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::placeholder {
      color: #aaa;
      font-size: 0.75rem;
    }
  }
  .invalid {
    border-color: #f85656;
  }
  .btn_send {
    color: white;
    background-color: colors.$blue_primary;
    height: 2.5rem;
    cursor: pointer;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    border: none;
    margin-bottom: 1rem;
  }
}
.email_sent {
  padding: 1rem;
}
.dots3 {
  height: 8px;
  width: 54px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dots3 span {
  position: absolute;
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.795);
  -webkit-animation: dots4 1.7s infinite ease-in-out both;
  animation: dots4 1.7s infinite ease-in-out both;
  margin-right: 5px;
}

.dots3 span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.dots3 span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.dots3 span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.dots3 span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@keyframes dots4 {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes dots4 {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 4rem 4rem;
    .img_container {
      width: 40%;
    }
    .form {
      width: 50%;
    }
  }
}
@media only screen and (min-width: 1280px) {
  .row {
    margin: 0 auto;
    max-width: 900px;
  }
}
