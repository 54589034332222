.container {
  text-align: center;
  margin-bottom: 1.75rem;
  svg {
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 1.5rem;
    margin: 0;
  }
  h3 {
    font-weight: 400;
    margin-top: 0.5rem;
  }
}
