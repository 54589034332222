@use "src/assets/styles/colors.scss";
@use "src/assets/styles/constants.scss";
.container {
  height: calc(100vh - #{constants.$navigation-height});
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
  flex-direction: column;
  color: #4e4e4e;
  width: 100%;
  // padding: 0 1rem;
  // background-color: rgb(163, 163, 163);
  // padding: 5rem 0 0 0;
  // margin-bottom: 6rem;
  text-align: center;
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  h3 {
    font-weight: 400;
    margin: 0;
    font-size: 1rem;
  }

  .illustration {
    // height: 300px;
    // max-width: 90%;
    width: 70%;
    margin-bottom: -3.25vh;
    margin-top: 1rem;
  }
}
.text {
  display: flex;
  align-items: center;
  flex: 1;
  h1 {
    font-size: 32px;
    margin: 0;
    // margin-top: 5rem;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    margin-bottom: 0.75rem;
    opacity: 0.8;
  }
}
.description {
  // background-color: #3d84cc;
  color: white;
  flex: 1;
  padding: 1rem;
  width: 100%;
  max-height: 300px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.description::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: -18%;
  width: 140%;
  // height: 30px;
  z-index: -1;
  background-color: #3d84cc;
  // border-top-left-radius: 50%;
  // border-top-left-radius: 50%;
  // border-top: 15px solid #da4b32;
  border-radius: 50% 50% 0 0;
}

@media only screen and (min-width: 640px) {
}
@media only screen and (min-width: 640px) {
  .container {
    height: auto;
    // max-height: 100vh;
    align-items: center;
    justify-content: center;
    color: #4e4e4e;
    padding: 0;

    h1 {
      font-size: 2.5rem;
      margin: 0;
    }
    h2 {
      // margin-bottom: 6rem;
    }
    .illustration {
      height: 300px;
      max-width: 90%;
      margin-bottom: -1.25rem;
    }
  }
  .text {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
  .description p {
    max-width: 300px;
    margin: auto;
    margin-bottom: 2rem;
  }
}
